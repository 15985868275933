import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Axios from 'axios'

let Sidebar = ({}) => {
  let dispatch = useDispatch()
  let isMobile = useSelector(state => state.isMobile)
  let url = useSelector(state => state.url)
  let user = useSelector(state => state.user)

  let showSideBar = useSelector(state => state.showSideBar)
  let setTab = tab => dispatch({type: 'set', payload: {tab, showSideBar: false}})
  let screenHeight = useSelector(state => state.screenHeight)
  let widthCalc = isMobile ? 'calc(100vw - 250px)' : 'calc(100vw - 400px)'

  let connect = () => {
    console.log('connect')
  }
  let goUser = () => {
    console.log('goUser')
  }
  let logout = async () => {
    let res = await Axios.get(`${url}/logout`)
    dispatch({type: 'set', payload: {user: {}, showSideBar: false}})
  }

  let menu = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#555',
        width: isMobile ? 250 : 400,
        paddingTop: 20,
        height: screenHeight,
      }}>
      {user?.uid && (
        <button className={'sideBarText'} style={{marginTop: 22}} onClick={() => logout()}>
          logout
        </button>
      )}

      <div
        style={{
          height: 0.5,
          backgroundColor: '#fbf7f5',
          width: 36,
          opacity: 0.2,
          marginTop: 14,
        }}
      />
    </div>
  )
  if (user?.name) {
    menu = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#555',
          width: isMobile ? 250 : 400,
          height: screenHeight,
          paddingTop: 40,
        }}>
        {false && (
          <button className={'sideBarText'} style={{marginTop: 20}} onClick={() => goUser()}>
            profile
          </button>
        )}
        <button className={'sideBarText'} style={{marginTop: 14}} onClick={() => setTab('invite')}>
          invite
        </button>
        {user?.userType == 'admin' && (
          <button className={'sideBarText'} style={{marginTop: 14}} onClick={() => setTab('analytics')}>
            analytics
          </button>
        )}
        <div
          style={{
            height: 0.5,
            backgroundColor: '#fbf7f5',
            width: 36,
            opacity: 0.2,
            marginTop: 14,
          }}
        />
        <button className={'sideBarText'} style={{marginTop: 8, opacity: 0.6}} onClick={() => {}}>
          logout
        </button>
      </div>
    )
  }
  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        width: '100vw',
        transform: showSideBar ? 'translateX(0)' : `translateX(100vw)`,
        transition: 'transform 250ms ease-in-out',
        zIndex: 50,
        height: screenHeight,
        zIndex: 150,
      }}>
      <div
        onClick={() => dispatch({type: 'toggleSidebar'})}
        style={{
          width: widthCalc,
          height: screenHeight + 80,
        }}
      />
      {menu}
    </div>
  )
}
export default Sidebar
